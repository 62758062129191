import {Calendar} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import React, {FC, useEffect, useState} from "react";
import dayjs from "dayjs";

import styles from './Date.module.css';
import classNames from "classnames";
import {MessageInstance} from "antd/es/message/interface";

interface DateProps {
    value: dayjs.Dayjs | undefined;
    onChange: (date: dayjs.Dayjs) => void;
    messageApi: MessageInstance;
}

// МЕСЯЦ-ЧИСЛО-ГОД
const vacation = [
    dayjs('11-11-2024'.replace(/-/g, '/')),
    dayjs('12-11-2024'.replace(/-/g, '/')),
    dayjs('13-11-2024'.replace(/-/g, '/')),
    dayjs('14-11-2024'.replace(/-/g, '/')),
    dayjs('15-11-2024'.replace(/-/g, '/')),
    dayjs('16-11-2024'.replace(/-/g, '/')),
    dayjs('17-11-2024'.replace(/-/g, '/')),
    dayjs('18-11-2024'.replace(/-/g, '/')),
].map(date => date.format('DD-MM-YYYY'));


const DateComponent: FC<DateProps> = ({value, messageApi, onChange}) => {
    const [minDateOrder, setMinDateOrder] = useState<dayjs.Dayjs>();
    const [maxDateOrder, setMaxDateOrder] = useState<dayjs.Dayjs>();

    useEffect(() => {
        const minDate = dayjs().add(15, "hour").startOf('day');

        setMinDateOrder(minDate);
        setMaxDateOrder(minDate.add(2, "month"));
    }, []);


    return (
        <Calendar
            locale={locale}
            fullscreen={false}
            validRange={minDateOrder && maxDateOrder ? [minDateOrder, maxDateOrder] : undefined}
            value={value}
            onSelect={(newValue) => !vacation.includes(newValue.format('DD-MM-YYYY')) && onChange(newValue)}
            defaultValue={undefined}
            dateFullCellRender={(date) => {
                const isVacation = vacation.includes(date.format('DD-MM-YYYY'));

                return(
                    <div onClick={(e) => {
                        if (isVacation) {
                            messageApi.info("Выбранная дата недоступна", 10);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    }} className={classNames(styles.cell, {
                        [styles.cellSelected]: date.format('DD-MM-YYYY') === value?.format('DD-MM-YYYY'),
                        "ant-picker-cell-disabled": isVacation,
                        [styles.cellVacation]: isVacation,
                    })}>{date.format('D')}</div>
                )}
            }
        />
    );
}

export default DateComponent;
